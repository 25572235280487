import React from 'react'
import Img from 'gatsby-image'
import styles from './MarketingCard.module.scss'

const MarketingCard = ({ title, description, image, file }) => {
  const extension = String(file).split('.')
  return (
    <div className={styles.card}>
      <Img
        fixed={image}
        className={styles.image}
        alt={description && description}
      />
      <div className={styles.card_body}>
        {title && <p>{title}</p>}
        <a target="_blank" href={file} className={styles.download}>
          <img
            src={`/icons/download.svg`}
            className={styles.arrow}
            alt="small arrow pointing right"
          />
          <span>{extension[extension.length - 1].toUpperCase()}</span>
        </a>
      </div>
    </div>
  )
}

export default MarketingCard
