import React from 'react'
import FaqContent from '../../faqContent/index.js'
import FaqTabs from '../../faqTabs/index.js'

const SpurtOgSvarad = ({ faq_obj: faqObj }) => {
  const questionContent = faqObj.map((item) => {
    return item.acf.questions
  })

  return (
    <section>
      <div className='container'>
        <div className='row '>
          <div className='col-md-12'>
            <FaqContent>
              <FaqTabs
                tabList={faqObj}
                tabListMobile={questionContent}
                tabContents={questionContent}
              />
            </FaqContent>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SpurtOgSvarad
