import React from 'react'
import cn from 'classnames'
import styles from './FeaturedSection.module.scss'
import Link from '../../Link'
import Img from 'gatsby-image'
import { get } from 'lodash'

/* eslint-disable camelcase */
const FeaturedSection = ({
  image,
  text,
  layout,
  circle_color,
  button_text,
  button_link,
}) => {
  const imageOnRight = layout === 'image right'

  const textProps = {
    text,
    imageOnRight,
    buttonText: button_text,
    buttonLink: button_link,
  }

  const imageProps = {
    image: get(image, 'localFile.childImageSharp.fixed', {}),
    circleColor: circle_color,
  }

  return (
    <div className={cn('my-5 my-lg-0', styles.background)}>
      <div className="container">
        <div
          className={cn(
            'row align-items-center flex-lg-row offset-lg-1 pb-5 py-lg-4',
            imageOnRight && 'flex-column-reverse'
          )}
        >
          {imageOnRight ? (
            <>
              <TextColumn {...textProps} />
              <ImageColumn {...imageProps} />
            </>
          ) : (
            <>
              <ImageColumn {...imageProps} />
              <TextColumn {...textProps} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const ImageColumn = ({ image, circleColor }) => (
  <div className="col-lg-5">
    <div className={styles.imageContainer}>
      <Img
        fixed={image}
        alt=""
        className={cn(styles.image, {
          [styles.imagePink]: circleColor === 'pink',
          [styles.imageTransparent]: circleColor === 'transparent',
        })}
      />
    </div>
  </div>
)

const TextColumn = ({ text, imageOnRight, buttonText, buttonLink }) => (
  <div
    className={cn(
      'col-lg-6 pt-2 pt-lg-0',
      imageOnRight ? 'pr-lg-5' : 'pl-lg-5',
      styles.text
    )}
  >
    <div
      className={cn('wp-content', styles.textContent)}
      dangerouslySetInnerHTML={{ __html: text }}
    />
    <div className="d-flex justify-content-center justify-content-lg-start">
      {buttonText && buttonLink && (
        <Link to={buttonLink} className={cn(styles.ctaButton)}>
          {buttonText}
        </Link>
      )}
    </div>
  </div>
)

export default FeaturedSection
