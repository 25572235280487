// Generated code
import React from 'react'

export default ({ name, caption, index }) => {
  return (
    <option defaultValue={name} value={index} key={index}>
      {caption}
    </option>
  )
}
