import React from 'react'

const SvgComponent = props => (
  <svg width="603" height="155" viewBox="0 0 603 155" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M309.708 11.2638C308.458 16.0736 303.546 18.9585 298.736 17.7085C293.927 16.4577 291.042 11.5459 292.291 6.73615C293.542 1.92639 298.455 -0.958449 303.264 0.29155C308.074 1.54228 310.958 6.45408 309.708 11.2638Z" fill="#C01082"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M309.708 148.264C308.458 153.074 303.546 155.958 298.736 154.708C293.927 153.458 291.042 148.546 292.291 143.736C293.542 138.926 298.455 136.042 303.264 137.292C308.074 138.542 310.958 143.454 309.708 148.264Z" fill="#C01082"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7084 148.264C16.4577 153.074 11.5458 155.958 6.73597 154.708C1.92686 153.458 -0.958026 148.546 0.291265 143.736C1.54201 138.926 6.45463 136.042 11.2637 137.292C16.0736 138.542 18.9585 143.454 17.7084 148.264Z" fill="#C01082"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M602.708 148.264C601.458 153.074 596.546 155.958 591.736 154.708C586.927 153.458 584.042 148.546 585.291 143.736C586.542 138.926 591.455 136.042 596.264 137.292C601.074 138.542 603.958 143.454 602.708 148.264Z" fill="#C01082"/>
    <path d="M301 10.5V147.5" stroke="#C01082" stroke-width="2" stroke-linecap="square"/>
    <path d="M9 146C9 146 9 109.756 9 105C9 100.244 13.4263 94.1655 20 94.1655C26.5737 94.1655 301 94.1655 301 94.1655" stroke="#C01082" stroke-width="2"/>
    <path d="M111.5 87.5L181 87" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M493.264 100.5L530 100" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M594 146C594 146 594 109.756 594 105C594 100.244 589.574 94.1655 583 94.1655C576.426 94.1655 301 94.1655 301 94.1655" stroke="#C01082" stroke-width="2"/>
    <path d="M191.5 87H199.5" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M296 37.7083V58.7917" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M296 37.7083V58.7917" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M296 66.1666V68.9166" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
  </svg>
)

export default SvgComponent
