import React from 'react'

const SvgComponent = props => (
  <svg width="415" height="155" viewBox="0 0 415 155" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M216.708 11.2638C215.458 16.0736 210.546 18.9585 205.736 17.7085C200.927 16.4577 198.042 11.5459 199.291 6.73615C200.542 1.92639 205.455 -0.958449 210.264 0.29155C215.074 1.54228 217.958 6.45408 216.708 11.2638Z" fill="#C01082"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7084 148.264C16.4577 153.074 11.5458 155.958 6.73597 154.708C1.92686 153.458 -0.958026 148.546 0.291265 143.736C1.54201 138.926 6.45463 136.042 11.2637 137.292C16.0736 138.542 18.9585 143.454 17.7084 148.264Z" fill="#C01082"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M414.708 148.264C413.458 153.074 408.546 155.958 403.736 154.708C398.927 153.458 396.042 148.546 397.291 143.736C398.542 138.926 403.455 136.042 408.264 137.292C413.074 138.542 415.958 143.454 414.708 148.264Z" fill="#C01082"/>
    <path d="M9 146C9 146 9 109.756 9 105C9 100.244 13.4263 94.1655 20 94.1655C26.5737 94.1655 207 94 207 94" stroke="#C01082" stroke-width="2"/>
    <path d="M56 101H107" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M406 146C406 146 406 109.756 406 105C406 100.244 401.574 94.1655 395 94.1655C388.426 94.1655 208 94 208 94" stroke="#C01082" stroke-width="2"/>
    <path d="M208 10.5V94" stroke="#C01082" stroke-width="2" stroke-linecap="square"/>
    <path d="M202 37.7083V58.7917" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M202 37.7083V58.7917" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M202 66.1667V68.9167" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M282.5 87H316" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M116 101H119" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M123 101H126" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
  </svg>
)

export default SvgComponent
