import React from 'react'
import styles from './HeroImageWLink.module.scss'
import TopHero from '../TopHero'
import Link from '../../Link'
import cn from 'classnames'

const HeroImageWLink = ({ image, link, mobile_image: mobileImage }) => {
  return (
    <Link to={link} className={cn('container d-block', styles.container)}>
      <div className='row'>
        <div className='col-12 px-0 position-relative'>
          <TopHero className='d-none d-lg-block' image={image.localFile.childImageSharp.fluid} />
          <img
            className={cn('d-block d-lg-none', styles.image)}
            src={mobileImage.localFile.childImageSharp.fluid.src}
          />
        </div>
      </div>
    </Link>
  )
}

export default HeroImageWLink
