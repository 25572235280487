import React from 'react'
import { get } from 'lodash'
import { hyphenate } from '../../../utils'

export default ({ content, title }) => {
  return (
    <section>
      <div className='container'>
        <div className='row pt-5'>
          <div className='col-md-12 col-lg-10'>
            {title && <h1>{hyphenate(title)}</h1>}
            <article
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
