// Generated code
import React, { useContext } from 'react'
import Link from '../Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import { LanguageContext } from '../../context'

export default ({ children }) => {
  const { currentLang: language } = useContext(LanguageContext)
  const currLang = language !== null ? language : 'is'
  const seeAll = currLang === 'is' ? {
    slug: 'frettir',
    text: 'Sjá allar fréttir'
  } : {
    slug: 'en/news',
    text: 'See all news'
  }

  return (
    <div className='news-summaries'>
      {children}
      <div className='row'>
        <div className='col d-flex justify-content-end'>
          <span className='link-more'>
            <Link to={seeAll.slug} className='link-more__link'>
              {seeAll.text}
            </Link>
            <FontAwesomeIcon className='ml-3' icon={faArrowRight} size='lg' />
          </span>
        </div>
      </div>
    </div>
  )
}
