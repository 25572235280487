import React from 'react'
import styles from './Kulumynd.module.scss'
import classNames from 'classnames'

const Kulumynd = ({ image }) => {
  const img = image.source_url

  return (
    <div className='container d-none d-md-block'>
      <div className='row py-3'>
        <div className={classNames(styles.imageWrapper, 'col my-md-5')}>
          <div className={styles.image}>
            <img src={img} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Kulumynd
