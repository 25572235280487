import React from 'react'
import styles from './HeroVideo.module.scss'
import ReactPlayer from 'react-player'
import cn from 'classnames'

const HeroVideo = ({ video, mobile_fallback_image }) => {
  const mobileFallbackImg = mobile_fallback_image?.localFile.url
  const videoDisplayClasses = cn (
    styles.contentContainer,
    'col-12',
    { 'd-none d-lg-block': !!mobileFallbackImg }
  )
  const mobileImageClasses = cn (
    { 'd-none': !mobileFallbackImg },
    'd-block d-lg-none',
    styles.mobileImgWrapper
  )
  return (
    <div className={cn('container', styles.container)}>
      <div className='row'>
        <div className={videoDisplayClasses}>
          <ReactPlayer loop playing muted width='100%' height='100%' url={video.source_url} />
        </div>
        {mobileFallbackImg &&
          <div className={mobileImageClasses}>
            <img src={mobileFallbackImg} />
          </div>
        }
      </div>
    </div>
  )
}

export default HeroVideo
