import React from 'react'
import IconCard from '../../IconCard'

const IconCardList = ({ cards }) => {
  return cards.map((x, index) => {
    const props = {
      image: x.icon,
      text: x.text
    }
    return (
      <div
        key={index}
        className='mb-3'
      >
        <IconCard
          {...props}
        />
      </div>
    )
  })
}

export default data => {
  const {
    haegri: [right],
    vinstri: [left]
  } = data

  return (
    <section>
      <div className='container'>
        <div className='row'>
          {left && (
            <div className='col-md-5'>
              <h2 className='mb-4 pb-2' >{left.text}</h2>
              <IconCardList cards={left.card} />
            </div>
          )}
          {right && (
            <div className='col-md-6 offset-md-1 mt-5 mt-md-0'>
              <h2 className='mb-4 pb-2'>{right.text}</h2>
              <IconCardList cards={right.card} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
