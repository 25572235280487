import React from 'react'
import Img from 'gatsby-image'
import cn from 'classnames'
import styles from './Markadsefni.module.scss'
import { get } from 'lodash'
import MarketingCard from '../../MarketingCard'

const Markadsefni = ({ rows }) => {
  return (
    <div className={cn('container', styles.container)}>
      {rows &&
        rows.map((row, i) => (
          <div key={i}>
            <h2>{row.title}</h2>
            <div className={cn(styles.card_container)}>
              {row.markadsefni.map((card, j) => {
                const image_data = get(
                  card.image,
                  'localFile.childImageSharp.fixed',
                  {}
                )
                return (
                  <MarketingCard
                    key={j}
                    file={card.file.source_url}
                    image={image_data}
                    title={card.title}
                    description={card.description}
                  />
                )
              })}
            </div>
          </div>
        ))}
    </div>
  )
}

export default Markadsefni
