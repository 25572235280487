import React from 'react'

const SvgComponent = props => (
  <svg width="895" height="155" viewBox="0 0 895 155" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M456.708 11.2638C455.458 16.0736 450.546 18.9585 445.736 17.7085C440.927 16.4577 438.042 11.5459 439.291 6.73615C440.542 1.92639 445.455 -0.958449 450.264 0.29155C455.074 1.54228 457.958 6.45408 456.708 11.2638Z" fill="#C01082"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M309.708 148.264C308.458 153.074 303.546 155.958 298.736 154.708C293.927 153.458 291.042 148.546 292.291 143.736C293.542 138.926 298.455 136.042 303.264 137.292C308.074 138.542 310.958 143.454 309.708 148.264Z" fill="#C01082"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M602.708 148.264C601.458 153.074 596.546 155.958 591.736 154.708C586.927 153.458 584.042 148.546 585.291 143.736C586.542 138.926 591.455 136.042 596.264 137.292C601.074 138.542 603.958 143.454 602.708 148.264Z" fill="#C01082"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7084 148.264C16.4577 153.074 11.5458 155.958 6.73597 154.708C1.92686 153.458 -0.958026 148.546 0.291265 143.736C1.54201 138.926 6.45463 136.042 11.2637 137.292C16.0736 138.542 18.9585 143.454 17.7084 148.264Z" fill="#C01082"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M894.708 148.264C893.458 153.074 888.546 155.958 883.736 154.708C878.927 153.458 876.042 148.546 877.291 143.736C878.542 138.926 883.455 136.042 888.264 137.292C893.074 138.542 895.958 143.454 894.708 148.264Z" fill="#C01082"/>
    <path d="M9 146C9 146 9 109.756 9 105C9 100.244 13.4263 94.1655 20 94.1655C26.5737 94.1655 448 94.1655 448 94.1655" stroke="#C01082" stroke-width="2"/>
    <path d="M640.264 100.5L691 101" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M886 146C886 146 886 109.756 886 105C886 100.244 881.574 94.1655 875 94.1655C868.426 94.1655 448 94.1655 448 94.1655" stroke="#C01082" stroke-width="2"/>
    <path d="M448 10.5V94" stroke="#C01082" stroke-width="2" stroke-linecap="square"/>
    <path d="M301 95V148.5" stroke="#C01082" stroke-width="2" stroke-linecap="square"/>
    <path d="M594 95V148.5" stroke="#C01082" stroke-width="2" stroke-linecap="square"/>
    <path d="M442 37.7083V58.7917" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M442 37.7083V58.7917" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M442 66.1666V68.9166" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M116 87H268" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M298.5 87H332" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M700 101H703" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M707 101H710" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
  </svg>
)

export default SvgComponent
