import React from 'react'
import Soluadilar from './Soluadilar'
import Markadsefni from './Markadsefni'
import ImageWithAside from './ImageWithAside'
import ConnectionAvailabilityForm from '../ConnectionAvailabilityForm'
import NewsSummaries from './NewsSummaries'
import Pontunarvaktin from './Pontunarvaktin'
import Content from './Content'
import SpurtOgSvarad from './SpurtOgSvarad'
import Kulumynd from './Kulumynd'
import StaffSection from './StaffSection'
import SmallImagewithAside from './SmallImageWithAside'
import VideoFullWidth from './VideoFullWidth'
import IconList from './IconList'
import ConnectionFormWithImg from './ConnectionFormWithImg'
import HeroVideo from './HeroVideo'
import KauphollFeed from './KauphollFeed'
import HeroImageWLink from './HeroImageWLink'
import KauphollFeedLatest from './KauphollFeedLatest'
import Table from './Table'
import FeaturedSection from './FeaturedSection'

const sectionTypes = {
  WordPressAcf_soluadilar: Soluadilar,
  WordPressAcf_markadsefni: Markadsefni,
  WordPressAcf_image_with_aside: ImageWithAside,
  WordPressAcf_get_eg_tengst: ConnectionAvailabilityForm,
  WordPressAcf_nyjustu_blogg_faerslurnar: NewsSummaries,
  WordPressAcf_pontunarvaktin: Pontunarvaktin,
  WordPressAcf_faq: SpurtOgSvarad,
  WordPressAcf_content: Content,
  WordPressAcf_kulumynd: Kulumynd,
  WordPressAcf_staff_display: StaffSection,
  WordPressAcf_small_img_with_aside: SmallImagewithAside,
  WordPressAcf_video_full_width: VideoFullWidth,
  WordPressAcf_iconcard_columns: IconList,
  WordPressAcf_simple_banner_img_on_side: ConnectionFormWithImg,
  WordPressAcf_hero_section_video: HeroVideo,
  WordPressAcf_rss_feed: KauphollFeed,
  WordPressAcf_frontpage_hero_w_link: HeroImageWLink,
  WordPressAcf_rss_feed_latest: KauphollFeedLatest,
  WordPressAcf_table: Table,
  WordPressAcf_featured_section: FeaturedSection,
}

const Sections = ({ sections }) => {
  if (!sections || !sections.length) {
    return null
  }

  return sections.map((section, index) => {
    if (sectionTypes.hasOwnProperty(section.__typename)) {
      const Cmp = sectionTypes[section.__typename]
      return <Cmp key={index} {...section} />
    }

    return null
  })
}

export default Sections
