import React from 'react'

const SvgComponent = props => (
  <svg width="18" height="155" viewBox="0 0 18 155" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7084 11.2638C16.4577 16.0736 11.5458 18.9585 6.73597 17.7085C1.92686 16.4577 -0.958026 11.5459 0.291265 6.73615C1.54201 1.92639 6.45463 -0.958449 11.2637 0.29155C16.0736 1.54228 18.9585 6.45408 17.7084 11.2638Z" fill="#C01082"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7084 148.264C16.4577 153.074 11.5458 155.958 6.73597 154.708C1.92686 153.458 -0.958026 148.546 0.291265 143.736C1.54201 138.926 6.45463 136.042 11.2637 137.292C16.0736 138.542 18.9585 143.454 17.7084 148.264Z" fill="#C01082"/>
    <path d="M9 10.5V94" stroke="#C01082" stroke-width="2" stroke-linecap="square"/>
    <path d="M9 95V148.5" stroke="#C01082" stroke-width="2" stroke-linecap="square"/>
    <path d="M3 37.7083V58.7917" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M3 37.7083V58.7917" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
    <path d="M3 66.1667V68.9167" stroke="#C01082" stroke-width="2" stroke-linecap="round"/>
  </svg>
)

export default SvgComponent
