import React from 'react'
import styles from './Table.module.scss'
import cn from 'classnames'

/* eslint-disable camelcase */
const Table = data => {
  return (
    <section className="container pb-5 d-flex flex-column">
      <span className={styles.heading}>
        <h3 className={styles.title}>{data?.title ?? ''}</h3>
        {data?.subtitle && (
          <span className={styles.subtitle}>{data?.subtitle}</span>
        )}
      </span>
      {data?.rows && (
        <div className={styles.table}>
          {data?.rows.map((row, i) => {
            let icon =
              row?.left_text.match(/\[(.*?)\]/g)?.[0]?.slice(1, -1) ?? ''

            return (
              <div
                key={i}
                className={cn(styles.row, icon ? styles.noTop : null)}
              >
                {icon ? (
                  <div className={styles.container}>
                    <img
                      src={`/icons/${icon}.svg`}
                      className={styles.arrow}
                      alt="small arrow pointing right"
                    />
                  </div>
                ) : (
                  <span>{row?.left_text}</span>
                )}
                <div className={styles.possible_extra}>
                  <span>{row?.right_text}</span>
                  <span>{row?.subtext}</span>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </section>
  )
}

export default Table
