import React, { useState } from 'react'
import styles from './BoardMember.module.scss'
import Modal from 'react-modal'
import classNames from 'classnames'
import Link from '../../Link/'
import Close from '../../Svg/Close.js'

export default ({ boardMember, isSmall }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const closeModal = () => {
    setModalIsOpen(false)
  }

  const {
    name,
    job_title: title,
    email,
    description
  } = boardMember

  const image = boardMember.image
  const sourceUrl = image ? image.source_url : null

  const imageClasses = classNames(
    { [styles.imageSmall]: isSmall },
    styles.image,
    'mb-4'
  )

  const headingClasses = classNames(
    styles.heading,
    { [styles.headingSmall]: isSmall }
  )

  return (
    <>
      <div className={classNames('d-flex flex-column align-items-center', styles.container)}>
        <button onClick={() => setModalIsOpen(true)}>
          {sourceUrl &&
            <div
              className={imageClasses}
              style={{ backgroundImage: `url(${sourceUrl})` }}
            />
          }
        </button>
        <div className={headingClasses}>
          <h2>{name}</h2>
          <p>{title}</p>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className='Modal'
        contentLabel='Example Modal'
      >
        <div className='Modal__content position-relative'>
          <div className='d-flex justify-content-end'>
            <button className={styles.closeButton} onClick={closeModal}>
              <Close />
            </button>
          </div>
          <div className='d-flex'>
            {sourceUrl &&
              <div
                className={classNames(styles.modalImage, styles.image)}
                style={{ backgroundImage: `url(${sourceUrl})` }}
              />
            }
            <span className={classNames(styles.heading, styles.modalHeading)}>
              <h2>{name}</h2>
              <p>{title}</p>
              <p>{description}</p>
              <div>
                <Link className={styles.email} to={'mailto:' + email}>{email}</Link>
              </div>
            </span>
          </div>
        </div>
      </Modal>
    </>
  )
}
