import React, { Component } from 'react'
import Cmp from './NewsSummaries.js'
import { StaticQuery, graphql } from 'gatsby'

class Connected extends Component {
  render () {
    return (
      <StaticQuery
        query={graphql`
          query {
            isNews: allWordpressPost(
              limit: 3
              sort: { fields: [date], order: DESC }
              filter: { kk_language: { slug: { eq: "is" } } }
            ) {
              edges {
                node {
                  id
                  slug
                  modified
                  date
                  status
                  title
                  content
                  path
                  featured_media {
                    localFile {
                      childImageSharp {
                        fluid(quality: 92) {
                          base64
                          aspectRatio
                          src
                          srcSet
                          srcWebp
                          srcSetWebp
                          sizes
                          originalImg
                          originalName
                          presentationWidth
                          presentationHeight
                        }
                      }
                    }
                  }
                  __typename
                  categories {
                    id
                  }
                  author {
                    id
                    name
                    avatar_urls {
                      wordpress_24
                      wordpress_48
                      wordpress_96
                    }
                    slug
                  }
                  format
                  excerpt
                  link
                  type
                }
              }
            }
            enNews: allWordpressPost(
              limit: 3
              sort: { fields: [date], order: DESC }
              filter: { kk_language: { slug: { eq: "en" } } }
            ) {
              edges {
                node {
                  id
                  slug
                  modified
                  date
                  status
                  title
                  content
                  path
                  featured_media {
                    localFile {
                      childImageSharp {
                        fluid(quality: 92) {
                          base64
                          aspectRatio
                          src
                          srcSet
                          srcWebp
                          srcSetWebp
                          sizes
                          originalImg
                          originalName
                          presentationWidth
                          presentationHeight
                        }
                      }
                    }
                  }
                  __typename
                  categories {
                    id
                  }
                  author {
                    id
                    name
                    avatar_urls {
                      wordpress_24
                      wordpress_48
                      wordpress_96
                    }
                    slug
                  }
                  format
                  excerpt
                  link
                  type
                }
              }
            }
          }
        `}
        render={data => {
          return <Cmp {...data} />
        }}
      />
    )
  }
}

export default Connected
