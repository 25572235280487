import React, { useState, useEffect, Fragment, useContext } from 'react'
import classnames from 'classnames'
import Modal from 'react-modal'
import axios from '../../../node_modules/axios'
import moment from 'moment'
import 'moment/locale/is'
import styles from './OrderStatus.module.scss'
import SetupDate from '../SetupDate'
import { hyphenate } from '../../utils'
import { useScrollBodyLock } from '../../hooks'
import { TRANSLATIONS } from '../../misc/translations.js'
import { LanguageContext } from '../../context'

const API = 'https://thjonustuvefur.ljosleidarinn.is'

moment.locale('is')
Modal.setAppElement('body')

export default () => {
  const { currentLang: language } = useContext(LanguageContext)
  const lang = TRANSLATIONS.hasOwnProperty(language) && TRANSLATIONS[language]

  const [ order, setOrder ] = useState({
    ssn: '',
    timestamp: null,
    token: ''
  })

  const { lock, unlock } = useScrollBodyLock()
  const [ submitting, setSubmitting ] = useState(false)
  const [ message, setMessage ] = useState('')
  const [ ssnIsValid, setSsnIsValid ] = useState(false)
  const [ modalIsOpen, setModalIsOpen ] = useState(false)
  const [ orders, setOrders ] = useState([])
  const [ orderId, setOrderId ] = useState(null)
  const [ visibleOrder, setVisibleOrder ] = useState(null)

  useEffect(() => {
    setSsnIsValid(order.ssn.match(/^\d{6}-\d{4}$/))
  }, [order])

  const setSsn = ssn => {
    return setOrder({ ...order, ssn })
  }

  const setToken = token => {
    return setOrder({ ...order, token })
  }

  const handleSsnChange = e => {
    const { value } = e.target

    setMessage('')

    if (!value) {
      return setSsn(value)
    }

    const onlyNums = value.replace(/[^\d]/g, '')

    if (onlyNums.length <= 6) {
      return setSsn(onlyNums)
    }

    return setSsn(`${onlyNums.slice(0, 6)}-${onlyNums.slice(6, 10)}`)
  }

  const handleTokenChange = e => {
    const { value } = e.target

    setMessage('')
    setToken(value)
  }

  const sendSsn = () => {
    if (submitting) {
      return false
    }

    if (!ssnIsValid) {
      setMessage(lang.ssnValidation)
      return false
    }

    setMessage(`${lang.aMoment}...`)
    setSubmitting(true)

    axios({
      method: 'POST',
      url: API + '/pantanir/vidskiptavinur/token',
      data: {
        ssn: order.ssn.replace(/[^\d]/g, '')
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        setMessage(`${lang.enterCode}`)
        setOrder({ ...order, timestamp: Date.now() })
        // gaEvent('pantanavakt', 'sms_auth_request_token_success')
      })
      .catch(response => {
        setMessage(`${lang.orderstatusUnavailable}`)
        // gaEvent('pantanavakt', 'sms_auth_request_token_fail')
      })
      .finally(() => {
        setSubmitting(false)
      })

    return true
  }

  const sendToken = () => {
    if (!order.token) {
      setMessage(lang.codeMissingMsg)
      return false
    }

    if (submitting) {
      return false
    }

    setMessage(`${lang.aMoment}...`)
    setSubmitting(true)

    const url = API + '/pantanir/vidskiptavinur/orders'

    axios({
      method: 'POST',
      url,
      data: {
        ssn: order.ssn.replace(/[^\d]/g, ''),
        token: order.token
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        setMessage(lang.success)
        setOrders(response.data)
        // gaEvent('pantanavakt', 'get_pontun_success')
      })
      .catch(() => {
        setMessage(lang.orderstatusUnavailable)
        // gaEvent('pantanavakt', 'get_pontun_fail')
      })
      .finally(() => {
        setSubmitting(false)
      })

    return true
  }

  return (
    <Fragment>
      <section>
        <div className={classnames(styles.container)}>
          <div className='container'>
            <div className='row'>
              <div className='col-md-10'>
                <h1 className={classnames(styles.heading)}>
                  {hyphenate(lang.myOderStatus)}
                </h1>
              </div>
            </div>
            {!orders.length &&
              <div className='row'>
                <div className='col-lg-5 offset-lg-1'>
                  <div className={classnames(styles.inputWrapper)}>
                    {!order.timestamp &&
                      <Fragment>
                        <input
                          value={order.ssn}
                          placeholder={lang.ssn}
                          className={classnames(styles.input)}
                          onChange={handleSsnChange}
                          onKeyDown={e => {
                            if (e.keyCode === 13) {
                              sendSsn()
                            }
                          }}
                        />
                        <button
                          className={styles.submit}
                          onClick={sendSsn}
                        >
                          <span
                            className={styles.arrow}
                          />
                        </button>
                      </Fragment>
                    }
                    {order.timestamp &&
                      <Fragment>
                        <input
                          value={order.token}
                          placeholder={lang.code}
                          className={classnames(styles.input)}
                          onChange={handleTokenChange}
                          onKeyDown={e => {
                            if (e.keyCode === 13) {
                              sendToken()
                            }
                          }}
                        />
                        <button
                          className={styles.submit}
                          onClick={sendToken}
                        >
                          <span
                            className={styles.arrow}
                          />
                        </button>
                      </Fragment>
                    }
                  </div>
                  <div className={classnames('pt-3', styles.message)}>
                    <p dangerouslySetInnerHTML={{ __html: message || '&nbsp;' }} />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <p className={classnames(styles.info)}>
                    {lang.orderWatchInfoText}
                  </p>
                </div>
              </div>
            }
            <div className='row'>
              {orders.length > 0 && (
                <div className='col-md-12'>
                  <h4>{orders.length} {lang.ordersFound}.</h4>
                </div>
              )}
              {orders.map((order, index) => (
                <div
                  key={index}
                  className='col-md-4'
                >
                  <div>{lang.order} <strong>#{order.referenceId}</strong></div>
                  <div>{lang.from} {moment(order.createdDT).format('d.M.Y')}</div>
                  <div>{order.address}</div>
                  <div>{lang.apartment} {order.apartment}</div>
                  <button
                    className='button-link mt-2'
                    onClick={() => {
                      setVisibleOrder(order.orderId)
                    }}
                  >
                    <span>{lang.view}</span>
                  </button>
                </div>
              ))}
              {orders.map((order, index) => (
                <div
                  key={index}
                  className={classnames({
                    [styles.orderHidden]: visibleOrder !== order.orderId
                  })}
                >
                  <div dangerouslySetInnerHTML={{ __html: order.markup }} />
                  {order.setupDT && (
                    <h4 className='mt-3'>{lang.houseCallBooked} {moment(order.setupDT).format('DD. MMMM, YYYY')}.</h4>
                  )}
                  {!order.setupDT && order.canBook === 'YES' && (
                    <button
                      className='button-link mt-3'
                      onClick={() => {
                        setOrderId(order.orderId)
                        setModalIsOpen(true)
                      }}
                    >
                      <span>{lang.bookHouseCall}</span>
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={lock}
          onAfterClose={() => {
            setOrderId(null)
            unlock()
          }}
          onRequestClose={() => setModalIsOpen(false)}
          className={styles.setupDateModal}
          overlayClassName={styles.setupDateOverlay}
          shouldCloseOnEsc
        >
          <SetupDate
            onBookingFinished={() => {
              sendToken()
            }}
            onCloseModal={() => {
              setModalIsOpen(false)
            }}
            ssn={order.ssn.replace(/[^\d]/g, '')}
            token={order.token}
            orderId={orderId}
          />
        </Modal>
      </section>
    </Fragment>
  )
}
