import React, { useContext } from 'react'
import OrderStatus from '../../OrderStatus'
import { TRANSLATIONS } from '../../../misc/translations.js'
import { LanguageContext } from '../../../context'

const SHOW_BANNER = false

const Pontunarvaktin = () => {
  const { currentLang: language } = useContext(LanguageContext)

  return (
    <>
      {SHOW_BANNER ? (
        <div className='container'>
          <div className='row'>
            <div className='col py-4 px-5 announcements'>
              <div dangerouslySetInnerHTML={{ __html: TRANSLATIONS[language].ANNOUNCEMENT }} />
            </div>
          </div>
        </div>
      ) : (
        <OrderStatus />
      )}
    </>
  )
}

export default Pontunarvaktin
