import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import styles from './Soluadilar.module.scss'
import { get, shuffle } from 'lodash'

const Soluadilar = ({ soluadilar }) => {
  const [ currentTab, setCurrentTab ] = useState(0)
  const [ tabs, setTabs ] = useState([])

  useEffect(() => {
    if (soluadilar && !tabs.length) {
      setTabs(shuffle(soluadilar.map(x => {
        return {
          ...x,
          logo: get(x, 'logo.source_url', ''),
          tab_image: get(x, 'tab_image.source_url', '')
        }
      })))
    }
  }, [])

  return (
    <div className={classnames('container', styles.container)}>
      <div className={classnames(styles.tabsContainer)}>
        <ul className='tabs tabs-clients'>
          {tabs.map((tab, index) => {
            return (
              <li
                key={index}
                className={classnames(styles.tab, {
                  selected: index === currentTab
                })}
              >
                <a onClick={() => {
                  setCurrentTab(index)
                }}>
                  <img src={tab.tab_image} />{tab.show_name && ` ${tab.name}`}
                </a>
              </li>
            )
          })}
        </ul>
        {tabs.map((tab, index) => {
          if (currentTab === index) {
            return (
              <div
                key={index}
                className={classnames('tabs-content tabs-content-clients', styles.content)}
              >
                <section className={classnames('tab-content', styles.section)}>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <img src={tab.logo} />
                    </div>
                    <div
                      className='col-lg-5 lead'
                      dangerouslySetInnerHTML={{ __html: tab.content }}
                    />
                    <div className={classnames(styles.info, 'col-lg-4')}>
                      <ul>
                        <li>
                          <span className='ss-globe' />
                          <a
                            href={tab.url}
                            target='_blank'
                          >
                            {tab.url}
                          </a>
                        </li>
                        <li>
                          <span className='ss-mail' />
                          <a href={`mailto:${tab.email}`}>
                            {tab.email}
                          </a>
                        </li>
                        <li>
                          <span className='ss-location' /> {tab.address}
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              </div>
            )
          }

          return null
        })}
      </div>
      <div className={classnames(styles.accordionContainer)}>
        <div className='accordion accordion-client-list'>
          <div
            className='panel-group'
            id='accordion'
          >
            {tabs.map((tab, index) => {
              const active = index === currentTab

              return (
                <div
                  key={index}
                  className={classnames('panel panel-default')}
                >
                  <div className={classnames('panel-heading', styles.panelHeading)}>
                    <h2 className='panel-title'>
                      <a
                        onClick={e => {
                          const el = e.target

                          setTimeout(() => {
                            const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
                            const yOffset = -100

                            window.scrollTo({
                              top: yCoordinate + yOffset,
                              behavior: 'smooth'
                            })
                          }, 600)

                          setCurrentTab(index)
                        }}
                        data-toggle='collapse'
                        aria-expanded='false'
                      >
                        <img src={tab.tab_image} />{tab.show_name && ` ${tab.name}`}
                      </a>
                    </h2>
                  </div>
                  <div
                    id={`collapse${index}`}
                    className={classnames('panel-collapse-sale', styles.panel, {
                      [styles.isExpanded]: active,
                      [styles.isCollapsed]: !active
                    })}
                    aria-expanded='false'
                  >
                    <div className='panel-body'>
                      <div className='row'>
                        <div className='col-lg-3'>
                          <img src={tab.logo} />
                        </div>
                        <div
                          className='col-lg-5 lead'
                          dangerouslySetInnerHTML={{ __html: tab.content }}
                        />
                        <div className='col-lg-4'>
                          <ul>
                            <li>
                              <span className='ss-globe' />&nbsp;
                              <a
                                href={tab.url}
                                target='_blank'
                              >
                                {tab.url}
                              </a>
                            </li>
                            <li>
                              <span className='ss-mail' />&nbsp;
                              <a href={`mailto:${tab.email}`}>
                                {tab.email}
                              </a>
                            </li>
                            <li>
                              <span className='ss-location' /> {tab.address}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Soluadilar
