import React, { useEffect, useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSpinnerThird,
  faArrowRight,
  faArrowLeft
} from '@fortawesome/pro-light-svg-icons'
import KauphollFeedItem from '../../KauphollFeedItem'
import { TRANSLATIONS } from '../../../misc/translations'
import { LanguageContext } from '../../../context'
import cn from 'classnames'

const FeedItems = ({ data }) => {
  return data.map(({ Url, ReleaseDateTime, Title }, key) => (
    <KauphollFeedItem
      key={key}
      title={Title}
      url={Url}
      date={ReleaseDateTime}
    />
  ))
}

const KauphollFeed = ({ title, text, url }) => {
  const { currentLang: language } = useContext(LanguageContext)
  const t = TRANSLATIONS.hasOwnProperty(language) && TRANSLATIONS[language]
  const [data, setData] = useState([])
  const [currData, setCurrData] = useState([])
  const itemsOnPage = 10
  const [start, setStart] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [currPage, setCurrPage] = useState(0)
  const [currLinks, setCurrLinks] = useState([])

  useEffect(() => {
    fetch(url)
      .then(response => response.json())
      .then(data => {
        setData(data)
        setCurrData(data.slice(start, start + itemsOnPage))
        setTotalPages(Math.ceil(data.length / itemsOnPage))
      })
  }, [itemsOnPage, start])

  useEffect(() => {
    setCurrLinks(getNavNumbers(currPage, totalPages))
  }, [currPage, totalPages])

  const getNavNumbers = (current, total) => {
    let pageNumbers = [...Array(total).keys()]
    if (total > 5) {
      // makes it so on the ends we have three numbers from the end instead of just two
      const offsets =
        current === 0 ? [0, 3] : current === total ? [2, 1] : [1, 2]
      const selected = [
        ...new Set([
          0,
          ...pageNumbers.slice(current - offsets[0], current + offsets[1]),
          total,
        ]),
      ]

      if (selected[1] !== 1) {
        selected.splice(1, 0, -1)
      }
      if (selected[selected.length - 2] !== total - 1) {
        selected.splice(-1, 0, -1)
      }
      pageNumbers = selected
    }

    let arr = pageNumbers.map((num, i) => {
      if (num === -1) {
        return (
          <span className="link-more" key={'p' + i}>
            ...
          </span>
        )
      }
      return (
        <button
          className={cn('remove-generic-styles link-more')}
          style={current === num ? { fontWeight: 700 } : { fontWeight: 400 }}
          key={'p' + i}
          onClick={() => loadNPage(num)}
        >
          {num + 1}
        </button>
      )
    })
    return arr
  }

  const loadNPage = n => {
    setStart(n * itemsOnPage)
    setCurrData(data.slice(start, start + itemsOnPage))
    setCurrPage(n)
  }

  const loadNextPage = () => {
    setStart(start + itemsOnPage)
    setCurrData(data.slice(start, start + itemsOnPage))
    setCurrPage(currPage + 1)
  }

  const loadPrevPage = () => {
    setStart(start - itemsOnPage)
    setCurrPage(currPage - 1)
    setCurrData(data.slice(start, start + itemsOnPage))
  }

  return (
    <div className="container vspace-l-top vspace-l-bottom">
      <div className="row">
        <div className="col-12 mb-4">
          {title && <h1>{title}</h1>}
          {text && (
            <div
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          )}
        </div>
      </div>
      {currData && currData.length > 0 ? (
        <FeedItems data={currData} />
      ) : (
        <div className="d-flex h-100 w-100 align-items-center justify-content-center pt-3">
          <FontAwesomeIcon
            icon={faSpinnerThird}
            size="4x"
            spin
            color="#264E5A"
          />
        </div>
      )}

      <div className={cn('w-100 d-flex mt-5 justify-content-between')}>
        {(currPage !== 0 && (
          <button className="remove-generic-styles" onClick={loadPrevPage}>
            <div className="d-flex justify-content-end link-more">
              <FontAwesomeIcon className="mr-3" icon={faArrowLeft} size="lg" />
              <div className="link-more__link">{t.PREV_PAGE}</div>
            </div>
          </button>
        )) || <div style={{ width: '130px' }}></div>}{' '}
        {/* here to avoid flexbox issues */}
        <span
          style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}
        >
          {currLinks}
        </span>
        {(currPage !== totalPages - 1 && (
          <button className="remove-generic-styles" onClick={loadNextPage}>
            <div className="d-flex justify-content-end link-more">
              <div className="link-more__link">{t.NEXT_PAGE}</div>
              <FontAwesomeIcon className="ml-3" icon={faArrowRight} size="lg" />
            </div>
          </button>
        )) || <div style={{ width: '130px' }}></div>}{' '}
        {/* here to avoid flexbox issues */}
      </div>
    </div>
  )
}

export default KauphollFeed
