import React, { useContext } from 'react'
import NewsSummaries from '../../NewsSummaries'
import NewsSummary from '../../NewsSummary'
import { get } from 'lodash'
import { useWindowSize } from '../../../hooks'
import { LanguageContext } from '../../../context'

export default data => {
  const windowSize = useWindowSize()
  const { currentLang: language } = useContext(LanguageContext)
  const newsArr = language === 'is' ? data.isNews.edges : data.enNews.edges

  const count = windowSize.width > 767
    ? 3
    : 1

  return (
    <section>
      <div className='container'>
        <NewsSummaries>
          <div className='row'>
            {newsArr.map((item, index) => {
              if (index < count) {
                const props = get(item, 'node', {})

                return (
                  <div key={index} className='col-lg-4 col-md-6'>
                    <NewsSummary
                      {...props}
                    />
                  </div>
                )
              }

              return null
            })}
          </div>
        </NewsSummaries>
      </div>
    </section>
  )
}
