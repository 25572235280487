import React, { useState, useEffect } from 'react'
import styles from './KauphollFeedLatest.module.scss'
import KauphollFeedItem from '../../KauphollFeedItem'
import Link from '../../Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import cn from 'classnames'

const KauphollFeedLatest = ({
  heading,
  link_text: linkText,
  link,
  text,
  url,
}) => {
  const [data, setData] = useState()

  useEffect(() => {
    fetch(url)
      .then(response => response.json())
      .then(data => setData(data))
  }, [])

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          {heading && <h2>{heading}</h2>}
          {text && <p className="mb-5">{text}</p>}
        </div>
      </div>
      <div className={cn({ [styles.container]: data && data.length > 2 })}>
        {data &&
          data.map(({ Title, Url, ReleaseDateTime }, key) => {
            return (
              <KauphollFeedItem
                key={key}
                title={Title}
                url={Url}
                date={ReleaseDateTime}
              />
            )
          })}
      </div>
      {link && linkText && (
        <div className="d-flex justify-content-end link-more">
          <Link className="link-more__link" to={link}>
            {linkText}
          </Link>
          <FontAwesomeIcon className="ml-3" icon={faArrowRight} size="lg" />
        </div>
      )}
    </div>
  )
}

export default KauphollFeedLatest
