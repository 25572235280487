import Cmp from './Page.js'
import { graphql } from 'gatsby'

export const pageQuery = graphql`
  query currentPageQuery($wordpress_id: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      slug
      title
      content
      wordpress_id
      acf {
        meta_title
        meta_description
        meta_image {
          localFile {
            url
          }
        }
        keywords
        sections_page {
          __typename
          ... on WordPressAcf_frontpage_nav_icons {
            link_bubble {
              isexternal
              key
              link
              title
            }
          }
          ... on WordPressAcf_rss_feed_latest {
            id
            text
            link_text
            link
            heading
            url
          }
          ... on WordPressAcf_small_img_with_aside {
            image {
              source_url
            }
            content
            links {
              link_title
              link
            }
          }
          ... on WordPressAcf_frontpage_hero_w_link {
            id
            link
            link_text
            image {
              source_url
              localFile {
                url
                childImageSharp {
                  id
                  fluid(maxWidth: 1400, quality: 92) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
            mobile_image {
              localFile {
                url
                childImageSharp {
                  id
                  fluid(maxWidth: 1400, quality: 92) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
            link
          }
          ... on WordPressAcf_video_full_width {
            url
          }
          ... on WordPressAcf_staff_display {
            link_name
            link_url
            staff_bottom_items {
              description
              email
              job_title
              image {
                source_url
              }
              name
            }
            staff_top_item {
              description
              email
              job_title
              name
              image {
                source_url
              }
            }
            title
          }
          ... on WordPressAcf_faq {
            faq_obj {
              id
              title
              acf {
                questions {
                  question
                  answer
                }
              }
            }
          }
          ... on WordPressAcf_iconcard_columns {
            id
            vinstri {
              card {
                text
                icon
              }
              text
            }
            haegri {
              text
              card {
                icon
                text
              }
            }
          }
          ... on WordPressAcf_kulumynd {
            id
            image {
              id
              source_url
              localFile {
                url
              }
            }
          }
          ... on WordPressAcf_image_with_aside {
            image {
              localFile {
                childImageSharp {
                  id
                  fluid(maxWidth: 730, quality: 92) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
            title
            paragraph
            link
            align
          }
          ... on WordPressAcf_pontunarvaktin {
            id
          }
          ... on WordPressAcf_icon_list {
            id
            texti_fyrir_ofan
            icons {
              icon
              content
            }
          }
          ... on WordPressAcf_get_eg_tengst {
            id
          }
          ... on WordPressAcf_content {
            content
          }
          ... on WordPressAcf_nyjustu_blogg_faerslurnar {
            id
          }
          ... on WordPressAcf_rss_feed {
            id
            title
            url
            text
          }
          ... on WordPressAcf_soluadilar {
            soluadilar {
              name
              content
              email
              url
              address
              show_name
              tab_image {
                source_url
              }
              logo {
                source_url
              }
              __typename
            }
          }
          ... on WordPressAcf_table {
            title
            subtitle
            rows {
              left_text
              right_text
              subtext
            }
            __typename
          }
          ... on WordPressAcf_hero_section_video {
            id
            video {
              source_url
              localFile {
                url
              }
            }
            mobile_fallback_image {
              localFile {
                url
              }
            }
          }
          ... on WordPressAcf_simple_banner_img_on_side {
            id
            connection_availability
            image {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_featured_section {
            id
            layout
            text
            button_link
            button_text
            circle_color
            image {
              localFile {
                childImageSharp {
                  fixed(width: 500, quality: 92) {
                    base64
                    tracedSVG
                    aspectRatio
                    width
                    height
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    originalName
                  }
                }
              }
            }
          }
          ... on WordPressAcf_markadsefni {
            __typename
            rows {
              title
              markadsefni {
                title
                description
                file {
                  source_url
                }
                image {
                  localFile {
                    childImageSharp {
                      fixed(width: 200, quality: 92) {
                        base64
                        tracedSVG
                        aspectRatio
                        width
                        height
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        originalName
                      }
                    }
                  }
                }
              }
            }
          }
        }
        title
        paragraph
        video {
          source_url
        }
        mobile_image {
          source_url
        }
        image {
          localFile {
            url
            childImageSharp {
              id
              fluid(maxWidth: 1400, quality: 92) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
    }
  }
`

export default Cmp
