// Generated code
import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import FaqTabMobile from '../faqTabMobile'
import FaqContent from '../faqContent'

export default ({ tabListMobile, tabList, tabContents }) => {
  const [currItem, setCurrItem] = useState({})
  const [openItem, setOpenItem] = useState()

  useEffect(() => {
    setCurrItem(tabList.length > 0
      ? tabList[0]
      : {}
    )
  }, [])

  const toggleCurrItem = item => {
    setCurrItem(item)
  }

  const toggleMobileItem = id => {
    tabList.map((elem) => elem.id === id ? setCurrItem(elem) : '')
  }

  const toggleCollapse = index => {
    openItem === index ? setOpenItem(null) : setOpenItem(index)
  }

  const currContent = currItem && currItem.acf ? currItem.acf.questions : []

  return (
    <div
      id='faq-tabs'
      className='panel tabs-vertical'
    >
      <select
        className='tabs-vertical-tablist-mobile'
        onChange={(e) => toggleMobileItem(e.target.value)}
      >
        {tabList.map((elem, i) => {
          const name = elem.title
          const id = elem.id
          return (
            <FaqTabMobile
              name={name}
              caption={name}
              index={id}
            />
          )
        })}
      </select>
      <ul className='tabs-vertical-tablist'>
        {tabList.map((item, i) => {
          return (
            <li
              className={classNames({ 'selected': item.id === currItem.id })}
              onClick={() => toggleCurrItem(item)}
              key={i}
            >
              <a>{item.title}</a>
            </li>
          )
        })}
      </ul>
      <div className='tabs-vertical-contents'>
        <div className='accordion'>
          <div className='panel-group' id='accordion0'>
            <FaqContent>
              {currContent.map((element, key) => (
                <div className='panel panel-default' key={key}>
                  <div onClick={() => toggleCollapse(key)} className='panel-heading'>
                    <h2 className='panel-title'>
                      <a>{element.question}</a>
                    </h2>
                  </div>
                  <div className={
                    classNames('panel-collapse',
                      { 'collapsed': openItem !== key },
                      { 'in': openItem === key })}
                  >
                    <div className='panel-body' dangerouslySetInnerHTML={{ __html: element.answer }} />
                  </div>
                </div>
              )
              )}
            </FaqContent>
          </div>
        </div>
      </div>
    </div>
  )
}
