import React from 'react'
import styles from './SmallImageWithAside.module.scss'
import classNames from 'classnames'
import Link from '../../Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'

const SmallImageWithAside = data => {
  const img = data?.image?.source_url
  const content = data.content
  const links = data.links
  return (
    <section className='container pb-5 d-flex flex-column flex-md-row'>
      {img &&
        <img className={classNames('mr-md-5 ', styles.image)} src={img} alt='image' />
      }
      <span className='mt-4 mt-md-0'>
        <span className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
        {links && links.map((item, i) => {
          const url = item.link
          const title = item.link_title
          return (
            <div className='link-more pb-2'>
              <Link className='link-more__link' to={url}>{title}</Link>
              <FontAwesomeIcon className='ml-3' icon={faArrowRight} size='lg' />
            </div>
          )
        })}
      </span>
    </section>
  )
}

export default SmallImageWithAside
