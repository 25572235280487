import React from 'react'
import styles from './KauphollFeedItem.module.scss'
import Link from '../Link'
import cn from 'classnames'
import { format } from 'date-fns'
import { is } from 'date-fns/locale'

const KauphollFeedItem = ({ title, url, date, key }) => {
  const formattedDate = format(new Date(date), 'd. MM yyyy', { locale: is })

  return (
    <div className={cn('row mx-1', styles.feedItem)} key={key}>
      <p className="col-md-2 mb-2 mb-md-0 d-block pl-0">{formattedDate}</p>
      <Link href={url} className="col-md-10">
        <h4>{title}</h4>
      </Link>
    </div>
  )
}

export default KauphollFeedItem
