// Generated code
import React, { useEffect, useState, useContext, useRef } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-light-svg-icons'
import classnames from 'classnames'
import { LanguageContext } from '../../context'
import { TRANSLATIONS } from '../../misc/translations.js'
import styles from './ConnectionAvailabilityForm.module.scss'
import pnr from './pnr'

const API = process.env.NODE_ENV === 'development'
  ? 'https://thjonustuvefur-test.ljosleidarinn.is'
  : 'https://thjonustuvefur.ljosleidarinn.is'

const Svg2 = props => (
  <svg className='aniborder' width='100%' height={82} {...props}>
    <defs>
      <filter id='glow'>
        <feGaussianBlur stdDeviation={2.5} result='coloredBlur' />
        <feMerge>
          <feMergeNode in='coloredBlur' />
          <feMergeNode in='SourceGraphic' />
        </feMerge>
      </filter>
    </defs>
    <g>
      <rect
        className='shape'
        height={82}
        width='100%'
        style={{
          filter: 'url(#glow)'
        }}
      />
    </g>
  </svg>
)

const initialSelectedStreet = {
  street: '',
  zip: ''
}

const initialOrder = {
  ssn: '',
  phone: '',
  email: '',
  postCode: '',
  serviceProvider: [],
  searchId: null,
  secret: null
}

const serviceProviderList = [
  {
    label: 'Hringiðan',
    name: 'hringidan',
    id: 'spcheck-01',
    logo: 'icon-logo-hringidjan'
  },
  {
    label: 'Vodafone',
    name: 'vodafone',
    id: 'spcheck-02',
    logo: 'icon-logo-vodafone'
  },
  {
    label: 'Hringdu',
    name: 'hringdu',
    id: 'spcheck-03',
    logo: 'icon-logo-hringdu'
  },
  {
    label: 'Nova',
    name: 'nova',
    id: 'spcheck-06',
    logo: 'icon-logo-nova'
  },
  {
    label: 'Kapalvæðing',
    name: 'kapalvaeding',
    id: 'spcheck-07',
    logo: 'icon-logo-kapalvaeding'
  },
  {
    label: 'Síminn',
    name: 'siminn',
    id: 'spcheck-08',
    logo: 'icon-logo-siminn'
  }
].sort(() => Math.random() - 0.5)

export default ({ mainHeading }) => {
  const { currentLang: language } = useContext(LanguageContext)
  const lang = TRANSLATIONS.hasOwnProperty(language) && TRANSLATIONS[language]

  let connectionHeading = 'Get ég tengst?'
  if (lang) {
    connectionHeading = lang.connectionHeading
  }
  const [ message, setMessage ] = useState('')
  const [ order, setOrder ] = useState(initialOrder)
  const [ connectionStatusCode, setConnectionStatusCode ] = useState(null)
  const [ title, setTitle ] = useState(mainHeading || connectionHeading)
  const [ searchString, setSearchString ] = useState('')
  const [ focusedId, setFocusedId ] = useState('')
  const [ thanksMessage, setThanksMessage ] = useState('')
  const [ selectedStreet, setSelectedStreet ] = useState(initialSelectedStreet)
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const [ isFetchingStreets, setIsFetchingStreets ] = useState(false)
  const [ isCheckingAvailability, setIsCheckingAvailability ] = useState(false)
  const [ streetList, setStreetList ] = useState([])
  const [ timer, setTimer ] = useState(null)
  const inputContainerRef = useRef()
  const inputRef = useRef()
  const streetListRef = useRef()
  const submitRef = useRef()

  const checkAvailability = () => {
    if (!selectedStreet.zip || !selectedStreet.street) {
      if (streetList.length > 0) {
        setSelectedStreet(streetList[0])
        setSearchString(renderStreet(streetList[0]))
        clearStreets()
        submitRef.current.focus()
      } else {
        setTitle(lang.noLjosleidariText)
        setMessage(
          lang.addressErrorText
        )
        setConnectionStatusCode(1)
        return false
      }
    }

    setIsCheckingAvailability(true)

    axios.get(`${API}/api/v1/leit/availability/${selectedStreet.zip}/${selectedStreet.street}`)
      .then(response => {
        const { connectionStatusCode: code, secret, searchId } = response.data

        const statusCode = code && parseInt(code, 10)

        setConnectionStatusCode(statusCode)
        setOrder({
          ...order,
          postCode: selectedStreet.zip,
          address: selectedStreet.street,
          ...(secret && { secret }),
          ...(searchId && { searchId })
        })

        switch (statusCode) {
          case 4:
            setTitle(`${lang.congratsYouCanConnect}`)
            setMessage(
              lang.congratsYouCanConnectMsg
            )
            break
          case 3:
            setTitle(lang.constuctionStartedTitle)
            setMessage(
              lang.constuctionStartedMsg
            )
            break
          case 2:
          case 1:
            setTitle(lang.noLjosleidariText)

            if (code === 1) {
              setMessage(
                lang.addressErrorText
              )
            } else {
              setMessage(
                lang.dreifbyliMsg
              )
            }

            break
          default:
            break
        }
      })
      .catch(response => {
        console.log('error', response)
      })
      .finally(() => {
        setIsCheckingAvailability(false)
      })
  }

  const getStreets = () => {
    setIsFetchingStreets(true)

    axios.get(API + '/api/v1/leit/addresses/' + searchString)
      .then(response => {
        setStreetList(response.data.streetList || [])
        if (!response.data.streetList) {
          checkAvailability()
        }
      })
      .catch(response => {
        console.log('error', response)
      })
      .finally(() => {
        setIsFetchingStreets(false)
      })
  }

  const clearStreets = () => setStreetList([])

  useEffect(() => {
    if (selectedStreet.street) {
      setSelectedStreet(selectedStreet)
      checkAvailability()
    }
  }, [selectedStreet])

  useEffect(() => {
    if (isFetchingStreets) {
      setSelectedStreet(initialSelectedStreet)
      setConnectionStatusCode(null)
      setTitle(connectionHeading)
      setMessage('')
      setThanksMessage('')
    }

    if (isCheckingAvailability) {
      setConnectionStatusCode(null)
      setTitle(`${lang.aMoment}...`)
      setMessage('')
      setThanksMessage('')
    }
  }, [isFetchingStreets, isCheckingAvailability])

  useEffect(() => {
    setTimer(clearTimeout(timer))

    if (!selectedStreet.street) {
      setTimer(setTimeout(searchString.length > 2 ? getStreets : clearStreets, 600))
    }
  }, [searchString])

  const renderStreet = x => {
    const zipString = x.zip && pnr[x.zip]

    return `${x.street}, ${zipString ? x.zip + ' ' + zipString : x.zip}`
  }

  const onSelectStreet = (e, x) => {
    e.preventDefault()

    setSelectedStreet(x)
    setSearchString(renderStreet(x))
    clearStreets()

    submitRef.current.focus()
  }

  const focusFirstItem = () => {
    if (streetListRef.current) {
      const firstItem = streetListRef.current.querySelector('[role="option"]')

      if (firstItem) {
        setFocusedId(firstItem.id)
      }
    }
  }

  const focusStreetList = () => {
    if (streetListRef.current) {
      streetListRef.current.focus()
    }
  }

  const onInputKeyDown = e => {
    const key = e.which || e.keyCode
    setSelectedStreet('')

    switch (key) {
      case 40:
        e.preventDefault()
        focusStreetList()

        break
      case 13:
        setSearchString(e.target.value)

        break
      default:
        break
    }
  }

  const onKeyDown = e => {
    const key = e.which || e.keyCode

    const currentItem = document.getElementById(focusedId)

    if (!currentItem) {
      return
    }

    let nextItem = null

    switch (key) {
      case 27:
        e.preventDefault()
        clearStreets()

        break
      case 38:
      case 40:
        e.preventDefault()

        if (key === 38) {
          nextItem = currentItem.previousElementSibling
        } else if (key === 40) {
          nextItem = currentItem.nextElementSibling
        }

        if (nextItem) {
          setFocusedId(nextItem.id)

          const container = streetListRef.current

          if (container && container.scrollHeight > container.clientHeight) {
            const scrollBottom = container.clientHeight + container.scrollTop
            const nextItemBottom = nextItem.offsetTop + nextItem.offsetHeight

            if (nextItemBottom > scrollBottom) {
              container.scrollTop = nextItemBottom - container.clientHeight
            } else if (nextItem.offsetTop < container.scrollTop) {
              container.scrollTop = nextItem.offsetTop
            }
          }
        }

        break
      case 13:
        currentItem.click()

        break
      default:
        break
    }
  }

  const onSelectProvider = name => {
    let serviceProvider = Object.assign([], order.serviceProvider)

    if (order.serviceProvider.indexOf(name) < 0) {
      serviceProvider.push(name)
    } else {
      serviceProvider = order.serviceProvider.filter(x => x !== name)
    }

    setOrder({
      ...order,
      serviceProvider
    })
  }

  const getSsn = value => {
    if (!value) {
      return value
    }

    const onlyNums = value.replace(/[^\d]/g, '')

    if (onlyNums.length <= 6) {
      return onlyNums
    }

    return `${onlyNums.slice(0, 6)}-${onlyNums.slice(6, 10)}`
  }

  const handleInput = e => {
    const { value, name } = e.target

    switch (name) {
      case 'ssn':
        setOrder({
          ...order,
          [name]: getSsn(value)
        })

        break
      default:
        setOrder({
          ...order,
          [name]: value
        })

        break
    }
  }

  useEffect(() => {
    const onOutsideClick = e => {
      if (inputContainerRef.current && !inputContainerRef.current.contains(e.target)) {
        clearStreets()
      }
    }

    document.addEventListener('click', onOutsideClick)

    return () => {
      document.removeEventListener('click', onOutsideClick)
    }
  }, [inputContainerRef])

  const onSuccess = data => {
    if (data && data.status === 'ok') {
      setTitle(lang.sendTipSuccessTitle)
      // New text in Icelandic but English should be left unchanged for now
      if (connectionStatusCode === 4 && language === 'is') {
        setMessage(lang.oneVisit)
      } else {
        setThanksMessage(lang.sendTipSuccessCanConnectMsg)
      }
      setConnectionStatusCode(null)
      clearStreets()
    } else {
      setMessage(lang.unableToSendRequest)
    }
  }

  const isFormValid = ['email', 'phone', 'ssn']
    .map(key => order[key].length > 0)
    .reduce((acc, val) => acc && val, true)

  const send = type => {
    setIsSubmitting(true)

    let url = null

    // let gaSuccess = () => {}
    // let gaFail = () => {}

    switch (type) {
      case 'request':
        url = `${API}/api/v1/leit/request-notification`
        // gaSuccess = data => gaEvent('availability', data.status === 'ok' ? 'fiber_request_success' : 'fiber_request_fail')
        // gaFail = () => gaEvent('availability', 'fiber_request_fail')

        break
      case 'order':
        url = `${API}/api/v1/leit/service-order`
        // gaSuccess = data => gaEvent('availability', data.status === 'ok' ? 'sales_request_success' : 'sales_request_fail')
        // gaFail = () => gaEvent('availability', 'sales_request_fail')

        break
      default:
        break
    }

    axios.post(url, order)
      .then(({ data }) => {
        onSuccess(data)
        // gaSuccess(data)
      })
      .catch(() => {
        // gaFail()
        setMessage('Ekki náðist samband við þjónustu.')
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const onSubmit = () => {
    if (!isFormValid) {
      return
    }

    switch (connectionStatusCode) {
      case 1:
      case 2:
      case 3:
        send('request')

        break
      case 4:
        send('order')

        break
      default:
        break
    }
  }

  return (
    <section className={classnames('hero hero-main', styles.wrapper)}>
      <div className='container'>
        <h1>
          {title}
        </h1>
        {thanksMessage && (
          <div className='row'>
            <div className='col-md-5 offset-md-1'>
              <span className='ss-like icon-large-pink' />
              <p className='t-thanks-details'>
                {thanksMessage}
              </p>
            </div>
          </div>
        )}
        <div className='row'>
          <div className='col-lg-8 col-md-12 offset-md-1'>
            <div className={classnames('connect-input', { 'connect-input__hide-arrow': searchString.length === 0 })}>
              <Svg2 />
              <div
                ref={inputContainerRef}
                className='connect-input-wrap'
              >
                <label htmlFor='input_address'>
                  <span className='ss-lightning' />
                </label>
                <input
                  ref={inputRef}
                  id='input_address'
                  type='text'
                  tabIndex={0}
                  value={searchString}
                  placeholder={lang.enterStreetName}
                  onChange={e => {
                    setSearchString(e.target.value, false)
                  }}
                  onKeyDown={onInputKeyDown}
                  autoComplete='off'
                />
                <input
                  ref={submitRef}
                  type='submit'
                  tabIndex={selectedStreet.zip ? 0 : -1}
                  className='input-arrow submit-arrow'
                  onClick={checkAvailability}
                  defaultValue='Athuga'
                />
                {streetList.length > 0 && (
                  <div
                    ref={streetListRef}
                    role='listbox'
                    onFocus={focusFirstItem}
                    onKeyDown={onKeyDown}
                    aria-activedescendant={focusedId}
                    tabIndex={0}
                    className={classnames(styles.streetList)}
                  >
                    {streetList.map((x, index) => {
                      const optionId = `option_id_${index}`

                      return (
                        <div
                          key={index}
                          role='option'
                          id={optionId}
                          tabIndex={0}
                          onKeyPress={() => {}}
                          onFocus={() => setFocusedId(optionId)}
                          aria-selected={x.street === selectedStreet.street}
                          onClick={e => onSelectStreet(e, x)}
                          className={classnames(styles.streetOption, {
                            [styles.streetOptionFocused]: optionId === focusedId
                          })}
                        >
                          {renderStreet(x)}
                        </div>
                      )
                    })}
                  </div>
                )}
                {streetList.length === 0 && isFetchingStreets && (
                  <div className={classnames(styles.streetList)}>
                    <div className={styles.streetOption}>
                      <FontAwesomeIcon icon={faSpinner} spin /> {lang.gettingList}...
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {message && (
          <div className='row vspace-m-bottom vspace-m-top'>
            <div className='col-md-12'>
              <p className='lead'>{message}</p>
            </div>
          </div>
        )}
        {connectionStatusCode && (
          <div className='row'>
            <div className='col-md-7 col-md-push-1 col-sm-7 col-xs-11 col-xs-push-0 col-sm-push-0'>
              <form>
                <div className='form-container'>
                  <input
                    type='text'
                    name='ssn'
                    value={order.ssn}
                    onChange={handleInput}
                    placeholder={lang.ssn}
                    required='required'
                  />
                  <input
                    type='tel'
                    name='phone'
                    value={order.phone}
                    onChange={handleInput}
                    placeholder={lang.phoneNumber}
                    required='required'
                  />
                  <input
                    type='email'
                    name='email'
                    value={order.email}
                    onChange={handleInput}
                    placeholder={lang.email}
                    required='required'
                  />
                </div>
              </form>

              <div className='footnote'>{lang.requiredFields}</div>
            </div>
          </div>
        )}
        {connectionStatusCode === 4 && (
          <div id='t-step-2-service' className='row hide vspace-m-top'>
            <div className='col-md-12 col-md-push-1'>
              <p className='lead'>{lang.chooseServiceProvider}</p>
            </div>
            <div className='col-md-7 col-md-push-1 col-sm-7 col-xs-11 col-xs-push-0 col-sm-push-0'>
              <form id='f-provider-form'>
                {serviceProviderList
                  .map(({ label, name, id, logo }, index) => {
                    return (
                      <div
                        className='client-checkbox'
                        key={index}
                      >
                        <input
                          type='checkbox'
                          className='t-service-provider'
                          onChange={() => onSelectProvider(name)}
                          name='t-service-provider[]'
                          defaultValue={name}
                          id={id}
                        />
                        <label
                          htmlFor={id}
                          className={logo}
                        >
                        </label>
                        <span>
                          {label}
                        </span>
                      </div>
                    )
                  })
                }
              </form>
            </div>
          </div>
        )}
        {connectionStatusCode && (
          <div className='row hide vspace-l-top'>
            <div className='col-md-3 col-xs-3 col-sm-3 col-xs-12 col-md-push-1'>
              <input
                type='submit'
                id="send-suggestion"
                value={lang.sendSuggestion}
                onClick={onSubmit}
                disabled={isSubmitting || !isFormValid}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
