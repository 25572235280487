// Generated code
import React from 'react'

export default ({ children }) => {
  return (
    <div
      className='tabs-vertical-content'
      id='tab-0'
    >
      {children}
    </div>
  )
}
