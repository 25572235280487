import React, { useContext } from 'react'
import Link from '../Link'
import styles from './NewsSummary.module.scss'
import { format } from 'date-fns'
import { is } from 'date-fns/locale'
import { get } from 'lodash'
import { hyphenate } from '../../utils'
import { LanguageContext } from '../../context'

export default props => {
  const {
    title,
    excerpt,
    slug,
    date,
    featured_media
  } = props

  const imageProps = get(featured_media, 'localFile.childImageSharp.fluid.src', {})
  const formattedDate = format(new Date(date), 'dd.MM.yyyy - HH:mm', { locale: is })

  const { currentLang: language } = useContext(LanguageContext)
  const parentSlug = language === 'is' ? 'frettir' : 'en/news'

  return (
    <article className='news-summary'>
      <Link to={`/${parentSlug}/${slug}/`}>
        <div>
          <div className={styles.imageContainer}>
            <img src={imageProps} />
          </div>
        </div>
        <h3 className='pt-4 pt-md-3'>
          <span className='ss-calendar' /> {formattedDate}
        </h3>
      </Link>
      <h1>
        <Link to={`/${parentSlug}/${slug}/`}>
          {hyphenate(title)}
        </Link>
      </h1>
      <div
        className='field-content'
        dangerouslySetInnerHTML={{ __html: excerpt }} />
    </article>
  )
}
