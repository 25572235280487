import React from 'react'
import styles from './StaffSection.module.scss'
import BoardMember from '../BoardMember'
import Link from '../../Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import cn from 'classnames'
import {
  SingleLine,
  DoubleLine,
  TrippleLine,
  QuadrupleLine,
} from '../../Svg'

const StaffSection = (staffMembers) => {
  const link = {
    name: staffMembers.link_name,
    url: staffMembers.link_url
  }

  const staffItemTop = staffMembers.staff_top_item
  const staffItemsBottom = staffMembers.staff_bottom_items

  return (
    <div className='container px-4 px-lg-0 mt-md-5 pt-md-4'>
      <div className='row'>
        <div className='col-12 align-items-center'>
          <h1 className='text-center pb-3'>{staffMembers.title}</h1>
          {link.url &&
            <div className='link-more text-center pb-4 mb-3'>
              <Link to={link.url} className='link-more__link'>{link.name}</Link>
              <FontAwesomeIcon className='ml-3' icon={faArrowRight} size='lg' />
            </div>
          }
        </div>
      </div>
      {staffItemTop && staffItemTop.map((item) => {
        return (
          <BoardMember boardMember={item} />
        )
      })}
        <div className={cn(
          styles.divider,
          'row d-none d-md-flex justify-content-center pt-3'
        )}>
          {staffItemsBottom.length === 1 && <SingleLine />}
          {staffItemsBottom.length === 3 && <TrippleLine />}
          {staffItemsBottom.length > 3 && <QuadrupleLine />}
          {staffItemsBottom.length === 2 && <DoubleLine />}
        </div>

      <div className={cn(styles.staffRow,
        'd-flex flex-wrap justify-content-center'
      )}>
        {staffItemsBottom && staffItemsBottom.map((item, i) => {
          const boardMemberClasses = cn(
            'mx-2 mx-md-5 text-center pt-4 mb-4 pb-md-0 justify-content-center',
            { 'col-md-3': staffItemsBottom.length < 3 },
            { 'col-md-2': staffItemsBottom.length >= 3 }
          )
          return (
            <div className={boardMemberClasses} key={item.wordpress_id}>
              <BoardMember boardMember={item} isSmall />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default StaffSection
