import cn from 'classnames'
import React, { useContext } from 'react'
import styles from './VideoFullWidth.module.scss'
import { LanguageContext } from '../../../context'
import { TRANSLATIONS } from '../../../misc/translations.js'

const VideoFullWidth = data => {
  const video = {
    url: data.url.match(/src="(.*?)"/)[1] ?? '',
    title: data.url.match(/title="(.*?)"/)[1] ?? '',
  }

  const { currentLang: language } = useContext(LanguageContext)
  const t = TRANSLATIONS[language]

  return (
    <section className={cn('container', styles.container)}>
      <div className={cn('w-100', styles.videoContainer)}>
        <div
          data-consent="analytics"
          data-inverse
          width="640"
          height="360"
          className={cn(styles.cookieBanner, 'ch2-open-settings-btn')}
        >
          <img
            src="/cookie_placeholder.jpg"
            alt="Must activate cookies to view this content"
          />
        </div>
        <iframe
          data-consent="analytics"
          data-src={video.url}
          title={video.title}
          width="640"
          height="360"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        />
      </div>
    </section>
  )
}

export default VideoFullWidth
