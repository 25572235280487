import React, { useContext } from 'react'
import styles from './ImageWithAside.module.scss'
import classnames from 'classnames'
import { get } from 'lodash'
import Img from 'gatsby-image'
import { hyphenate } from '../../../utils'
import Link from '../../Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import { LanguageContext } from '../../../context'

export default ({ title, paragraph, link, image, align }) => {
  const imageProps = get(image, 'localFile.childImageSharp.fluid', {})
  const { currentLang: language } = useContext(LanguageContext)
  const seeMore = language === 'is' ? 'Sjá meira' : 'See more'

  return (
    <div className={classnames('container', styles.container)}>
      <div className={classnames('row align-items-start', {
        'flex-lg-row-reverse': align === 'right'
      },
      'flex-column-reverse flex-lg-row'
      )}>
        <div
          className='col-lg-4 align-self-top mb-4'
        >
          {title &&
            <h1 className={styles.heading}>
              {hyphenate(title)}
            </h1>
          }
          {title &&
            <p className={styles.paragraph}>
              {paragraph}
            </p>
          }
          {link &&
            <span className='link-more'>
              <Link
                className='link-more__link'
                to={link}
              >
                {seeMore}
              </Link>
              <FontAwesomeIcon className='ml-3' icon={faArrowRight} size='lg' />
            </span>
          }
        </div>
        {image &&
          <div className='col-lg-8 pb-3'>
            <Img fluid={imageProps} />
          </div>
        }
      </div>
    </div>
  )
}
