import React, { useState, useEffect, Fragment } from 'react'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import classnames from 'classnames'
import axios from 'axios'
import { is } from 'date-fns/locale'
import moment from 'moment'
import 'moment/locale/is'
import styles from './SetupDate.module.scss'
import 'react-datepicker/dist/react-datepicker.css'
import './react-datepicker.css'

moment.locale('is')
registerLocale(is)
setDefaultLocale(is)

const API = process.env.NODE_ENV === 'development'
  ? 'https://thjonustuvefur-test.ljosleidarinn.is'
  : 'https://thjonustuvefur.ljosleidarinn.is'

export default ({ orderId, ssn, token, onCloseModal, onBookingFinished }) => {
  if (!token || !ssn || !orderId) {
    return null
  }

  const [startDate, setStartDate] = useState(new Date())
  const [isBookable, setIsBookable] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedDateStr, setSelectedDateStr] = useState(null)
  const [data, setData] = useState(null)
  const [slotsForSelectedDate, setSlotsForSelectedDate] = useState([])
  const [slotsByDay, setSlotsByDay] = useState({})
  const [selectedSlotId, setSelectedSlotId] = useState(null)
  const [orderInfo, setOrderInfo] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [timeString, setTimeString] = useState(false)
  const [submitSuccessful, setSubmitSuccessful] = useState(null)

  useEffect(() => {
    setIsFetching(true)
    const url = `${API}/pantanir/boka-heimsokn-laus-plass`

    axios({
      method: 'POST',
      url,
      data: {
        ssn,
        token,
        orderId
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        setIsFetching(false)
        setData(response.data)
      })
      .catch(() => {
        setIsFetching(false)
        setErrorMessage('Gat ekki sótt lausa daga. Vinsamlegast reyndu aftur síðar.')
      })
  }, [orderId])

  useEffect(() => {
    if (data) {
      setIsBookable(data.bookable)
      setOrderInfo(data.orderInfo)

      setSlotsByDay(data.slots.reduce((dates, slot) => {
        const key = moment(slot.workdate).format('YYYY-MM-DD')

        if (!dates[key]) {
          dates[key] = []
        }

        dates[key].push(slot)

        return dates
      }, {}))
    }
  }, [data])

  useEffect(() => setSelectedDateStr(selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : ''), [selectedDate])

  useEffect(() => {
    if (selectedDateStr) {
      const slots = slotsByDay[selectedDateStr]

      const noon = moment(selectedDate)
        .clone()
        .hour(12)
        .minute(0)
        .second(0)

      const userFriendlySlots = slots.map(slot => {
        const startTime = moment(slot.startTime)

        return {
          morning: startTime.isBefore(noon),
          start: startTime.format('HH:mm'),
          end: moment(slot.endTime).format('HH:mm'),
          slotId: slot.slotId
        }
      })

      const groups = userFriendlySlots.reduce((groups, slot) => {
        const targetGroup = slot.morning ? groups.morning : groups.afternoon

        targetGroup.push(slot)

        return groups
      }, {
        morning: [],
        afternoon: []
      })

      setSlotsForSelectedDate([
        {
          label: 'Fyrir hádegi',
          slots: groups.morning
        },
        {
          label: 'Eftir hádegi',
          slots: groups.afternoon
        }
      ])
    }
  }, [selectedDateStr])

  const handleChange = date => {
    setStartDate(date)
    setSelectedSlotId(null)
  }

  const minDate = new Date()
  const maxDate = moment()
    .add(6, 'M')
    .toDate()

  const includeDates = Object.keys(slotsByDay).map(key => new Date(key))

  const onSubmit = async () => {
    setIsSubmitting(true)
    const url = `${API}/pantanir/boka-heimsokn-senda`

    await axios({
      method: 'POST',
      url,
      data: {
        ssn,
        token,
        orderId,
        workDate: moment(selectedDate).format('YYYY-MM-DD'),
        slotId: selectedSlotId
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(() => {
        setSubmitSuccessful(true)
        onBookingFinished()
      })
      .catch(() => {
        setErrorMessage('Gat ekki bókað dagsetningu. Vinsamlegast reyndu aftur síðar.')
        setSubmitSuccessful(false)
      })

    setIsSubmitting(false)
  }

  if (submitSuccessful) {
    return (
      <div>
        <h2 className='mb-3'>HEIMSÓKN BÓKUÐ</h2>
        <div>
          Heimsókn hefur verið bókuð <span dangerouslySetInnerHTML={{ __html: timeString }} />
          þann <strong>{moment(selectedDate).format('DD. MMMM').toLowerCase()}</strong> næstkomandi.
        </div>
        <div className={classnames('pt-3', styles.buttons)}>
          <button
            className='button-link'
            onClick={onCloseModal}
          >
            <span>Loka</span>
          </button>
        </div>
      </div>
    )
  }

  if (errorMessage) {
    return (
      <div>
        <h2 className='mb-3'>VILLA!</h2>
        <div>
          {errorMessage}
        </div>
        <div className={classnames('pt-3', styles.buttons)}>
          <button
            className='button-link'
            onClick={onCloseModal}
          >
            <span>Loka</span>
          </button>
        </div>
      </div>
    )
  }

  if (isBookable === false) {
    return (
      <div>
        <h2 className='mb-3'>EKKI HÆGT AÐ BÓKA</h2>
        <div>
          Þú getur því miður ekki bókað heimsókn.
        </div>
        <div className={classnames('pt-3', styles.buttons)}>
          <button
            className='button-link'
            onClick={onCloseModal}
          >
            <span>Loka</span>
          </button>
        </div>
      </div>
    )
  }

  return (
    <div>
      <h2 className='mb-3'>BÓKA HEIMSÓKN</h2>
      {isFetching && (
        <div>
          <span className='spinner spinner--dark' />
          <span>Sæki lausa daga...</span>
        </div>
      )}
      {!isFetching && (
        <Fragment>
          {orderInfo.length && orderInfo.map(x => <div>{x}</div>)}
          <h3 className='pb-3 pt-3'>Veldu dagsetningu</h3>
          <DatePicker
            dateFormat={'yyyy-MM-dd'}
            selected={startDate}
            includeDates={includeDates}
            onChange={handleChange}
            onSelect={date => setSelectedDate(date)}
            monthsShown={2}
            minDate={minDate}
            maxDate={maxDate}
            inline
          />
          {selectedDate && slotsForSelectedDate.length && (
            <Fragment>
              <h3 className='pt-3 pb-3'>Veldu tíma</h3>

              {slotsForSelectedDate.map((section, index) => {
                if (section.slots.length) {
                  return (
                    <div key={index}>
                      <h4>{section.label}</h4>
                      <ul className={classnames('radio-group', styles.radioGroup)}>
                        {section.slots.map((slot, index) => {
                          const id = `${slot.slotId}-radio-id`

                          return (
                            <div key={index}>
                              <input
                                name='pickslot'
                                type='radio'
                                id={id}
                                value={slot.slotId}
                                onChange={() => {
                                  setSelectedSlotId(slot.slotId)
                                  setTimeString(`<strong>${section.label.toLowerCase()}</strong> milli klukkan <strong>${slot.start}</strong> og <strong>${slot.end}</strong> `)
                                }}
                                checked={selectedSlotId === slot.slotId}
                              />
                              <label
                                htmlFor={id}
                              >
                                {slot.start} - {slot.end}
                              </label>
                            </div>
                          )
                        })}
                      </ul>
                    </div>
                  )
                }

                return (
                  <h4 key={index}>Ekkert laust {section.label.toLowerCase()}</h4>
                )
              })}
            </Fragment>
          )}
        </Fragment>
      )}
      <div className={classnames('pt-3', styles.buttons)}>
        <button
          disabled={isSubmitting}
          className='button-link'
          onClick={onCloseModal}
        >
          <span>Hætta við</span>
        </button>
        {selectedSlotId && (
          <button
            disabled={isSubmitting}
            className='button-link'
            onClick={onSubmit}
          >
            {isSubmitting && (
              <span>
                <span className='spinner' />
                Augnablik...
              </span>
            )}
            {!isSubmitting && (
              <span>
                Bóka
              </span>
            )}
          </button>
        )}
      </div>
    </div>
  )
}
