import React from 'react'
import styles from './IconCard.module.scss'
import classnames from 'classnames'

export default ({ image, text, className }) => {
  return (
    <div className={classnames(className, styles.container)}>
      <div className={classnames(styles.card, 'mt-3')}>
        {image &&
          <div className={classnames(styles.image, 'mr-4')}>
            <img src={`/icons/${image}.svg`} />
          </div>
        }
        <div className={classnames(styles.content, 'text-left')}>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </div>
    </div>
  )
}
